.react-multi-carousel-track {
  @apply pb-9;
}
.react-multi-carousel-dot button {
  @apply border-keppel-100;
}
.react-multi-carousel-dot--active button {
  @apply bg-keppel-500;
}
.react-multi-carousel-dot-list {
  @apply bottom-1;
}
