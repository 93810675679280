@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@layer base {
  html {
    font-family: "Roboto", system-ui, sans-serif;
  }
}
:root:has(.no-doc-scroll) {
  @apply overflow-hidden;
}
body {
  @apply relative box-border;
}

.nav-link {
  @apply font-semibold hover:text-dark-green relative hover:after:content-[''] after:w-0 after:absolute after:left-0 after:bottom-0 after:bg-dark-green after:h-0.5 hover:after:w-full after:transition-all;
}

.mobile-nav li {
  @apply w-fit;
}

input,
select,
textarea {
  @apply rounded-md p-2;
}

* [aria-invalid="true"] {
  @apply border border-red-600 outline-red-600;
}

.error {
  @apply text-red-600 text-sm mt-1 ml-1;
}
